import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'

const modalOptions = {
  centered: true
}

const DeleteModal = ({ bodyText, modal, setModal, deleteConfirmed }) => {
  const toggle = () => setModal(!modal)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [modal])

  return (
    <Modal isOpen={modal} toggle={toggle} {...modalOptions}>
      <ModalHeader toggle={toggle}>Delete Summary</ModalHeader>
      <ModalBody>
        {bodyText}
      </ModalBody>
      <ModalFooter>
        {!loading &&
          <>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={() => {
              setLoading(true)
              deleteConfirmed()
            }}>
              Delete
            </Button>
          </>
        }
        {loading &&
          <Spinner color="primary" />
        }
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModal

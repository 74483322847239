export const defaultSettings = {
  id: 'default',
  exists: true,
  metadata: {
    action: 'Benefit',
    name: 'My action',
    feel: 'Very disappointed',
    model: 'gpt-4-0125-preview',
    temperature: 0.7,
    systemMessage: 'In the following is a list of user feedback regarding the main benefits of our app. Based on this feedback, create a list of feature requests that double down on the perceived user benefit.',
    maxTokens: 1024
  }
}

import React, { memo, useState } from 'react'
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from 'reactstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ReactComponent as NoAvatarIcon } from '../../../images/user-no-avatar.svg'
import { ReactComponent as ArrowIcon } from '../../../images/arrow-dropdown.svg'
import { userUpdateField } from '../../../api/users'

const Header = memo(({ signOutHandle }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const currentUser = useSelector((state) => state.userReducers.authUser)
  const name = currentUser?.displayName || currentUser?.email
  const account = useSelector((state) => state.accountReducers.account)
  const admin = account?.metadata?.admin || false
  const hostname = window.location.hostname
  const user = useSelector(state => state.userReducers.user)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const changeAccount = async (account) => {
    await userUpdateField('account', account)
    window.location.reload()
  }

  return (
    <div className='header'>
      <div className='header__user-container'>
        <Dropdown isOpen={isOpen} toggle={toggle} className='header__dropdown-container'>
          <DropdownToggle tag='span' className='header__dropdown'>
            <NoAvatarIcon />
            {name} {admin &&
              <>(admin)</>
            }
            <ArrowIcon />
          </DropdownToggle>
          <DropdownMenu
            end
            flip={false}
          >
            {/*
            <DropdownItem onClick={() => navigate('/admin/profile')}>
              Profile
            </DropdownItem>
            */}
            {hostname === 'localhost' &&
              <DropdownItem onClick={() => navigate('/onboarding')}>
                Onboarding
              </DropdownItem>
            }
            <DropdownItem onClick={() => signOutHandle()}>
              Sign-out
            </DropdownItem>
            {user?.metadata?.accounts.length > 1 &&
              <>
                <DropdownItem divider />
                {user.metadata.accounts.map((account) => (
                  <DropdownItem key={`account-${account}`} onClick={() => changeAccount(account)}>
                    {account}
                  </DropdownItem>
                ))}
              </>
            }
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  )
})

Header.displayName = 'Header'

export default Header
